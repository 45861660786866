





























import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {MachinePdf} from "@/models/machinePdf";
import DocumentPdfRepository from "@/api/DocumentPdfRepository";
import {RepositoryFactory} from "@/api/RepositoryFactory";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";

const pdfRepository: DocumentPdfRepository = RepositoryFactory.get('documentpdf');

@Component({
  components: {
    VuePdfEmbed
  }
})
export default class PdfTypeCardComponent extends Vue {

  @Prop({default: () => []})
  public nameProp!: MachinePdf;

  public documentPreview: any | null = null;
  public pdfSource: any | null = null;

  public async mounted() {
    this.documentPreview = await pdfRepository.getPDFDocument(this.nameProp)
    const blob = new Blob([this.documentPreview.data]);
    this.pdfSource = URL.createObjectURL(blob);
  }

  @Watch('nameProp')
  public async namePropChanged() {
    this.documentPreview = await pdfRepository.getPDFDocument(this.nameProp)
    const blob = new Blob([this.documentPreview.data]);
    this.pdfSource = URL.createObjectURL(blob);
  }

  public async downloadFile() {
    try {
      this.$emit('downloadFile', this.nameProp);
    } catch (e) {
      console.log('error');
    }
  }

  public async deleteFile() {
    try {
      this.$emit('deleteFile', this.nameProp);

    } catch (e) {
      // TODO: Show error
    }
  }


}
