























































































































import pdfService from "@/views/offer/pdf.service";
import {Component, Prop, Vue} from "vue-property-decorator";
import {Machine} from "@/models/machine";
import {RepositoryFactory} from "@/api/RepositoryFactory";
import {MachinePdf, MachinePdfType} from "@/models/machinePdf";
import DocumentPdfRepository from "@/api/DocumentPdfRepository";
import VuePdfEmbed from "vue-pdf-embed/dist/vue2-pdf-embed";
import VuePdf from "vue-pdf/src/vuePdfSss.vue";
import PdfTypeCardComponent from "../PdfTypeCard.component.vue";
import {MachineImageType} from "@/models/maintenance";


const pdfRepository: DocumentPdfRepository = RepositoryFactory.get('documentpdf');

@Component({
  methods: {pdfService},
  components: {
    PdfCardComponent: () => import('./../PdfCard.component.vue'),
    VuePdfEmbed,
    VuePdf,
    PdfTypeCardComponent
  }
})

export default class DocumentPdfDialogComponent extends Vue {

  @Prop({default: () => new Machine({})})
  public machine!: Machine;

  public dragover: boolean = false;


  public uploadedFiles: Array<any> = [];


  public pdfTypes: any[] = [];


  public async mounted() {
    this.pdfTypes = Object.values(MachinePdfType);
    this.pdfTypes = this.pdfTypes.map(type => {
      return {
        value: type, text: type.replace('pdf', '')
      }
    })
  }


  removeFile(filename: string) {
    // Find the index of the
    const index = this.uploadedFiles.findIndex(
        file => file.name === filename
    );
    // If file is in uploaded files remove it
    if (index > -1) this.uploadedFiles.splice(index, 1);
  }

  onDrop(dropEvent: any) {
    this.dragover = false;
    // If there are already uploaded files remove them
    if (this.uploadedFiles.length > 0)
      this.uploadedFiles = [];
    else {
      const files = Array.from(dropEvent.dataTransfer.files);
      files.forEach((element: any) => {

            element.pdfType = MachinePdfType.pdfElektro;
            element.url = URL.createObjectURL(element);
            this.uploadedFiles.push(element)
          }
      );
    }
  }

  public async downloadFile(item: MachinePdf) {

    try {
      const blobResponse = await pdfRepository.getPDFDocument(item)
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blobResponse.data);
      link.download = 'TODO:FILENAME.pdf';
      document.body.append(link);
      link.click();
      link.remove();
      // in case the Blob uses a lot of memory
      setTimeout(() => URL.revokeObjectURL(link.href), 7000);
    } catch (e) {
      console.log('error');
    }
  }


  public async deleteFile(pdfDoc: MachinePdf) {


    try {
      const response = await pdfRepository.deletePdf(pdfDoc);
      if (response.data.id) {
        this.machine.documentPdfs = this.machine.documentPdfs.filter(pdf => pdf.id !== pdfDoc.id);
      }
    } catch (e) {
      // TODO: Show error
    }
  }

  public async submit() {
    try {
      const promises = this.uploadedFiles.map(file =>
          pdfRepository.uploadPdf(file, file.pdfType, {
            machine: this.machine
          }));

      const responses = await Promise.all(promises);
      responses.forEach((pdfResponse, index) => {

        if (this.uploadedFiles[index]) {
          this.machine.documentPdfs.push(new MachinePdf(pdfResponse.data));

        }
      });
      this.uploadedFiles = [];
    } catch (e) {
      console.log('error');
    }
  }
}
